const Cookie = require('js-cookie');

//=========================
// Add To Homescreen MODULE
//=========================
function ATH() {

    // ATH Properties
    const PromptedCookie = 'ath-prompted';
    const InstalledCookie = 'ath-installed';
    const AthDOM = document.getElementsByClassName('ath')[0];
    const AthTitle = AthDOM.querySelector('.ath__head__title');


    //#region ATH DOM EVENTS

    // Show Button  (in Header Navigation)
    const showBtn = document.getElementById('ath-show-btn');
    showBtn.addEventListener('click', evt => {
        evt.preventDefault();
        ShowPrompt();
    });


    // Dismiss Prompt Button
    const dismissBtn = AthDOM.querySelector('.ath-dismiss-btn');
    dismissBtn.addEventListener('click', evt => {
        evt.preventDefault();
        DismissPrompt();
    });


    // Display INFORMATION page
    const helpBtn = AthDOM.querySelector('.ath-help-btn');
    helpBtn.addEventListener('click', evt => {
        evt.preventDefault();
        GoTo('help', 'Installing FSPromos4U');
    });


    // Back to HOME Instructions
    const backBtn = AthDOM.querySelector('.ath-back-btn');
    backBtn.addEventListener('click', evt => {
        evt.preventDefault();
        GoTo('home');
    });


    // Display ANDROID Instructions
    const androidBtn = AthDOM.querySelector('.ath-android-btn');
    androidBtn.addEventListener('click', evt => {
        evt.preventDefault();
        GoTo('android', 'Install on Android');
    });

    // Install ANDROID Button
    const installAndroidBtn = AthDOM.querySelector('.ath-install-android');
    installAndroidBtn.addEventListener('click', evt => {
        evt.preventDefault();

        deferredPromt.prompt();
        deferredPromt.userChoice
            .then(result => {
                console.log(result);
                deferredPromt = null;
            });
    });


    // Display IOS Instructions
    const iosBtn = AthDOM.querySelector('.ath-ios-btn');
    iosBtn.addEventListener('click', evt => {
        evt.preventDefault();
        GoTo('ios', 'Install on iOS');
    });

    /* Install IOS Button
        const installIosBtn = ath.querySelector('.ath-install-ios');
        installIosBtn.addEventListener('click', evt => {
            evt.preventDefault();

            deferredPromt.prompt();
            deferredPromt.userChoice
                .then(result => {
                    console.log(result);
                    deferredPromt = null;
                });
        });
    */

    //#endregion


    //#region ATH METHODS

    // Show ATH Prompt if cookie is not recognized
    function Initialize() {
        if (Cookie.get(InstalledCookie)) return Unmount();
        if (Cookie.get(PromptedCookie)) return Unmount();

        window.addEventListener('appinstalled', evt => {
            console.log('ATH Installed', evt);

            SetCookie(InstalledCookie, 730);
        });

        setTimeout(() => { ShowPrompt(); }, 1000);
    }

    // Remove this from the DOM if not needed
    function Unmount() {
        document.body.removeChild(AthDOM);
    }


    // Set the cookie provided for x days
    function SetCookie(cookie, x = 60) {
        Cookie.set(cookie, 1, { expires: x, });
    }


    // Display ATH Prompt
    function ShowPrompt() {
        AthDOM.classList.add('visible');
        SetCookie(PromptedCookie, 60);
    }


    // Hide ATH Prompt
    function DismissPrompt() {
        AthDOM.classList.remove('visible');
    }

    // Go To Prompt Page - Set Prompt Title
    function GoTo(page, title = 'Install FSPromos4U') {
        AthDOM.classList.remove('home', 'help', 'android', 'ios');
        AthDOM.classList.add(page);
        AthTitle.textContent = title;
    }

    //#endregion


    // Public API
    return { Initialize, };
}


// Deferred Installation Prompt
let deferredPromt = null;

window.addEventListener('beforeinstallprompt', function (evt) {
    evt.preventDefault();

    // Stash the event so it can be triggered later.
    deferredPromt = evt;
    return false;
});

// Determine if in Standalone Mode
if (window.matchMedia('(display-mode: standalone)').matches ||
    (
        window.navigator &&
        window.navigator.standalone === true
    )
) {
    console.log('display-mode is standalone');
} else {
    const ath = ATH();
    ath.Initialize();
}
